import React, { useEffect, useState, useMemo } from "react";
import { authHeader } from "../_helpers";
import axios from "axios";
import moment from "moment";
import Sidebar from "./component/Sidebar";
import TablePagination from "@mui/material/TablePagination";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Unstable_Grid2";
import { useDispatch, useSelector } from "react-redux";
import {
  useNavigate,
  useLocation,
  Navigate,
  useParams,
} from "react-router-dom";
import ToastMessageComp from "../component/ToastMessageComp";
import { alertActions } from "../_actions";
import InputAdornment from "@mui/material/InputAdornment";
//-- add ccode
import IconButton from "@mui/material/IconButton";
import { Menu } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CreateIcon from "@mui/icons-material/Create";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Anchor from "../_helpers/anchor";
import { MdDeleteOutline, MdEditSquare } from "react-icons/md";
import { FiSend } from "react-icons/fi";
import LoadingButton from "@mui/lab/LoadingButton";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { frFR } from "@mui/x-date-pickers/locales";
import dayjs from "dayjs";
import "dayjs/locale/fr.js";
import SaveIcon from "@mui/icons-material/Save";

export default function Clientdetails() {
  const alert = useSelector((state) => state.alert);
  const params = useParams();
  const dispatch = useDispatch();
  const [civilite, setCivilite] = useState("");

  const [userDetails, setUserDetails] = useState("");
  const [AnnounceData, setAnnounceData] = useState([]);
  const [AnnouncePendingData, setAnnouncePendingData] = useState([]);
  const [publicationDate, setPublicationDate] = useState("");
  const [openModel, setOpenModel] = useState(false);
  const [openModelDate, setOpenModelDate] = useState(false);
  const [successful, setSuccessful] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [editFormData, setEditFormData] = useState({
    billing_address_type: "another_address",
    billing_address_denomination: "",
    billing_address_civility: "",
    billing_address_nom: "",
    billing_address_prenom: "",
    billing_address_code_postal: "",
    billing_address_address: "",
    billing_address_ville: "",
    billing_address_ville_id: "",
    billing_address_villeArr: [],
  });
  //-- add code
  const [rowData, setRowData] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEls, setAnchorEls] = useState({});
  const [anchorElsPending, setAnchorElsPending] = useState({});
  const [ConfirmOpen, setConfirmOpen] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const visibleRows = useMemo(
    () =>
      stableSort(AnnounceData, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, AnnounceData]
  );
  const visibleRowsPending = useMemo(
    () =>
      stableSort(AnnouncePendingData, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, AnnouncePendingData]
  );
  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleClick1 = (event, id) => {
    // setAnchorEl(event.currentTarget);
    setAnchorEls((prev) => ({ ...prev, [id]: event.currentTarget }));
  };
  const handleClick1Pending = (event, id) => {
    // setAnchorEl(event.currentTarget);
    setAnchorElsPending((prev) => ({ ...prev, [id]: event.currentTarget }));
  };
  const handleClose1Pending = (id) => {
    // setAnchorEl(null);
    setAnchorElsPending((prev) => ({ ...prev, [id]: null }));
  };

  const handleClose1 = (id) => {
    // setAnchorEl(null);
    setAnchorEls((prev) => ({ ...prev, [id]: null }));
  };
  const handleCloseModel = () => {
    setOpenModel(false);
    setSubmitted(false);
  };
  const handleSubmitModel = async () => {
    /* console.log("editFormData",editFormData)
    console.log("rowData",rowData) */
    let billing_address_json_data = rowData.billing_address_json;
    let billing_address_json_parse = billing_address_json_data;
    if (billing_address_json_parse != null) {
      billing_address_json_parse = JSON.parse(billing_address_json_parse);
      billing_address_json_parse.billing_address_denomination =
        editFormData.billing_address_denomination;
      billing_address_json_parse.billing_address_civility =
        editFormData.billing_address_civility;
      billing_address_json_parse.billing_address_nom =
        editFormData.billing_address_nom;
      billing_address_json_parse.billing_address_prenom =
        editFormData.billing_address_prenom;
      billing_address_json_parse.billing_address_address =
        editFormData.billing_address_address;
      billing_address_json_parse.billing_address_code_postal =
        editFormData.billing_address_code_postal;
      billing_address_json_parse.billing_address_ville =
        editFormData.billing_address_ville;
      billing_address_json_parse.billing_address_villeArr =
        editFormData.billing_address_villeArr;
      billing_address_json_parse.billing_address_ville_id =
        editFormData.billing_address_ville_id;
    } else {
      billing_address_json_parse = editFormData;
    }

    setSuccessful(false);

    if (
      editFormData.billing_address_denomination &&
      editFormData.billing_address_civility &&
      editFormData.billing_address_nom &&
      editFormData.billing_address_prenom &&
      editFormData.billing_address_address &&
      editFormData.billing_address_code_postal &&
      editFormData.billing_address_ville_id
    ) {
      setSubmitted(false);
      setLoading(true);
      try {
        let sendData = {
          announce_id: rowData.id,
          editFormData: editFormData,
          billing_address_json: billing_address_json_parse,
        };
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}api/announce/update_invoice`,
          sendData
        );

        dispatch(alertActions.success(response.data.msg));
        setSuccessful(true);
        setOpenModel(false);
        setSubmitted(false);
        setLoading(false);
        getAllUsers(params.user_id);
      } catch (error) {
        console.error(error);
        dispatch(
          alertActions.error("Une erreur s'est produite. Veuillez réessayer!")
        );
        setSuccessful(true);
        setSubmitted(false);
        setLoading(false);
      }
    } else {
      setSubmitted(true);
      setLoading(false);
    }
  };
  const handleChangeModel = (e) => {
    let billing_address_json_parse = rowData.billing_address_json;
    let { name, value } = e.target;
    setEditFormData((editFormData) => ({ ...editFormData, [name]: value }));

    if (name == "billing_address_ville_id") {
      let filterCity = editFormData.billing_address_villeArr.filter(
        (cityVal) => cityVal.id === value
      );
      if (filterCity.length > 0) {
        setEditFormData((editFormData) => ({
          ...editFormData,
          ["billing_address_ville"]: filterCity[0].city,
        }));
      }
    }

    if (name == "billing_address_code_postal") {
      const getData = setTimeout(async () => {
        setEditFormData((editFormData) => ({
          ...editFormData,
          billing_address_ville: "",
          billing_address_ville_id: "",
          billing_address_villeArr: [],
        }));
        if (value.length > 0) {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}api/checkzipcode/${value}`
          );
          if (response.data && response.data.data) {
            setEditFormData((editFormData) => ({
              ...editFormData,
              billing_address_ville: response.data.data.city,
              billing_address_ville_id: response.data.data.id,
              billing_address_villeArr: response.data.data.cityArr,
            }));
          }
        }
      }, 1000);
      return () => clearTimeout(getData);
    }
    // setOpenModel(true)
    /* setConfirmOpen(true);
    setDeleteId(rowData.id); */
  };
  const handleClickEditInvoice = (e, rowData) => {
    handleClose1(rowData.id);
    setOpenModel(true);
    setRowData(rowData);
    if (rowData.billing_address_json != null) {
      let billing_address_json_parse = JSON.parse(rowData.billing_address_json);
      setEditFormData({
        billing_address_type: "another_address",
        billing_address_denomination:
          billing_address_json_parse.billing_address_denomination,
        billing_address_civility:
          billing_address_json_parse.billing_address_civility,
        billing_address_nom: billing_address_json_parse.billing_address_nom,
        billing_address_prenom:
          billing_address_json_parse.billing_address_prenom,
        billing_address_code_postal:
          billing_address_json_parse.billing_address_code_postal,
        billing_address_address:
          billing_address_json_parse.billing_address_address,
        billing_address_ville: billing_address_json_parse.billing_address_ville,
        billing_address_ville_id:
          billing_address_json_parse.billing_address_ville_id,
        billing_address_villeArr:
          billing_address_json_parse.billing_address_villeArr,
      });
    }
    /* setConfirmOpen(true);
    setDeleteId(rowData.id); */
  };
  const handleClickSend = (e, rowData) => {};
  const handleClickEdit = (e, rowData) => {
    // console.log("rowData",rowData)
    localStorage.setItem("fromAdmin", true);
    let url_title = rowData.title.replace(/\s+/g, "-");
    const url = `/nos-formulaires/${url_title}/${rowData.add_type}/${rowData.id}`;
    // const url = `/admin/modelelibre/${rowData.user_id}/${rowData.id}`;
    window.open(url, "_blank");
    // navigate(url);
    /* handleClose1();
    setOpenModel(true)
    setRowData(rowData);
    if(rowData.billing_address_json != null){
      let billing_address_json_parse = JSON.parse(rowData.billing_address_json);
      setEditFormData({
        "billing_address_type":"another_address",
        "billing_address_denomination":billing_address_json_parse.billing_address_denomination,
        "billing_address_civility":billing_address_json_parse.billing_address_civility,
        "billing_address_nom":billing_address_json_parse.billing_address_nom,
        "billing_address_prenom":billing_address_json_parse.billing_address_prenom,
        "billing_address_code_postal":billing_address_json_parse.billing_address_code_postal,
        "billing_address_address":billing_address_json_parse.billing_address_address,
        "billing_address_ville":billing_address_json_parse.billing_address_ville,
        "billing_address_ville_id":billing_address_json_parse.billing_address_ville_id,
        "billing_address_villeArr":billing_address_json_parse.billing_address_villeArr,
      })
    } */
  };
  const handleClick = (e, rowData) => {
    setConfirmOpen(true);
    setDeleteId(rowData.id);
    handleClose1(rowData.id);
  };

  const handleCancel = () => {
    setConfirmOpen(false);
  };

  const handleOk = async () => {
    setConfirmOpen(false);

    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}api/announce/device/${deleteId}`
      );
      if (response.data.sucess === true) {
        dispatch(alertActions.success(response.data.msg));
        getAllUsers(params.user_id);
        // setRows(prevItems =>prevItems.filter(item =>item.id !== rowData.deleteId));
      } else {
        dispatch(alertActions.error(response.data.msg));
      }
    } catch (error) {
      console.error(error);
    }
  };

  //-- code end

  const [userData, setUserData] = useState({
    id: "",
    siren: "",
    raisosociale: "",
    civil: "",
    firstname: "",
    lastname: "",
    telephone: "",
    email: "",
    ville: "",
    postalcode: "",
    accounttype: [],
    payment_plan: "",
    forfaitaires: "",
    modification: "",
    profile_image_old: "",
    updateType: "admin",
  });

  const handleChange = (event) => {
    if (event.target.name === "accounttype") {
      if (event.target.checked) {
        setUserData((userData) => ({
          ...userData,
          accounttype: [...userData["accounttype"], event.target.value],
        }));
      } else {
        setUserData((userData) => ({
          ...userData,
          accounttype: [
            ...userData["accounttype"].filter(
              (item) => item !== event.target.value
            ),
          ],
        }));
      }
    } else {
      setUserData((userData) => ({
        ...userData,
        [event.target.name]: event.target.value,
      }));
    }
  };

  useEffect(() => {
    // console.log("params",params)
    getAllUsers(params.user_id);
  }, []);

  async function getAllUsers(user_id) {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}api/user/${user_id}`
      );
      // console.log("response",response)
      setUserDetails(response.data.data);
      setAnnounceData(response.data.announce_data);
      setAnnouncePendingData(response.data.announce_pending_data);
      if (response.data.data) {
        setUserData({
          id: user_id,
          siren: response.data.data.siren,
          raisosociale: response.data.data.raisosociale,
          civil: response.data.data.civil,
          firstname: response.data.data.firstname,
          lastname: response.data.data.lastname,
          telephone: response.data.data.telephone,
          email: response.data.data.email,
          ville: response.data.data.ville,
          postalcode: response.data.data.postalcode,
          accounttype: response.data.data.accounttype
            ? response.data.data.accounttype.split(",")
            : [],
          payment_plan: response.data.data.payment_plan,
          profile_image_old: response.data.data.profile_image,
          forfaitaires: response.data.data.forfaitaires,
          modification: response.data.data.modification,
          updateType: "admin",
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleUpdate = async (user_data) => {
    // console.log("user_data",user_data)
    setSuccessful(false);
    try {
      // delete user_data["email"];
      /*  let formData = new FormData();
       Object.keys(user_data).forEach(fieldName => {
           formData.append(fieldName, user_data[fieldName]);
       }); */
      const response = await axios.put(
        process.env.REACT_APP_API_URL + `api/user/${user_data.id}`,
        user_data,
        authHeader()
      );
      setSuccessful(true);
      if (response.data.sucess === true) {
        dispatch(alertActions.success(response.data.msg));
        getAllUsers(params.user_id);
      } else {
        dispatch(alertActions.error(response.data.msg));
      }
      // const user_data = response.data;
    } catch (error) {
      console.error(error);
    }
  };

  const handleClickEditDate = (e, rowData) => {
    // console.log("rowData.date_parution",rowData.date_parution)
    setRowData(rowData);
    setPublicationDate(moment(rowData.date_parution).format("YYYY-MM-DD"));
    setOpenModelDate(true);
    handleClose1(rowData.id);
  };

  const handleCloseModelDate = () => {
    setOpenModelDate(false);
    setSubmitted(false);
  };
  const handleSubmitModelDate = async () => {
    // setOpenModelDate(false);
    setLoading(true);
    setSuccessful(false);
    let publishDate = moment(publicationDate).format("YYYY-MM-DD");

    if (rowData) {
      try {
        let sendData = {
          announce_id: rowData.id,
          pubishdate: publishDate,
        };
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}api/announce/changepublishdate`,
          sendData
        );
        console.log("response", response.data.data);
        /* setRows(prevItems =>
          prevItems.map(item =>
            item.id === rowData.id
              ? { ...item, date_parution: publishDate,attestation:response.data.data.attestation,votre_doc:response.data.data.votre_doc } // Update the specific field
              : item // Return the unchanged item
          )
        ); */
        dispatch(alertActions.success(response.data.msg));
        setSuccessful(true);
        setOpenModelDate(false);
        setSubmitted(false);
        setLoading(false);
        setOpenModelDate(false);
        handleClose1(rowData.id);
        getAllUsers(params.user_id);
      } catch (error) {
        console.error(error);
        dispatch(
          alertActions.error("Une erreur s'est produite. Veuillez réessayer!")
        );
        setSuccessful(true);
        setSubmitted(false);
        setLoading(false);
      }
    }
  };
  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" to="/">
      Tableau de bord
    </Link>,
    <Link underline="hover" key="1" color="inherit" to="/admin/clients">
      Gestion des clients
    </Link>,
    <Typography key="3">Tous les utilisateurs</Typography>,
  ];

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  const currentAdmin = useSelector((state) => state.administrator.admin);

  if (!currentAdmin) {
    return <Navigate to="/admin/login" />;
  }

  return (
    <>
      {successful ? (
        <ToastMessageComp message={alert.message} type={alert.type} />
      ) : (
        ""
      )}

      <div className=" w-full h-full bg-white p-8">
        <Typography
          variant="h4"
          component="h4"
          fontWeight={500}
          color={"#1CBE93"}
        >
          Tous les utilisateurs
        </Typography>
        <Stack spacing={2} mb={3}>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </Stack>
        <Grid container spacing={4}>
                <Grid xs={4}>
                  <Typography
                    variant="h6"
                    component="h6"
                    fontWeight={400}
                    sx={{ textAlign: "left" }}
                    color={"#27295B"}
                  >
                    Les informations personnelles
                  </Typography>
                  <div className="flex flex-col justify-center items-center gap-4 pt-24">
                    <svg
                      width="120"
                      height="120"
                      viewBox="0 0 61 61"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M30.5 0C13.664 0 0 13.664 0 30.5C0 47.336 13.664 61 30.5 61C47.336 61 61 47.336 61 30.5C61 13.664 47.336 0 30.5 0ZM30.5 12.2C36.3865 12.2 41.175 16.9885 41.175 22.875C41.175 28.7615 36.3865 33.55 30.5 33.55C24.6135 33.55 19.825 28.7615 19.825 22.875C19.825 16.9885 24.6135 12.2 30.5 12.2ZM30.5 54.9C24.3085 54.9 16.9885 52.399 11.773 46.116C17.1154 41.9244 23.7095 39.6464 30.5 39.6464C37.2905 39.6464 43.8846 41.9244 49.227 46.116C44.0115 52.399 36.6915 54.9 30.5 54.9Z"
                        fill="#DEE3E2"
                      />
                    </svg>

                    <h3 className="text-lg text-primary font-semibold">
                      {userData.firstname} {userData.lastname}
                    </h3>
                    <div>
                      <Button
                        component={Link}
                        // to={`/admin/modelelibre/${userData.id}`}
                        to={{
                          pathname: `/admin/modelelibre/${userData.id}/0`,
                          state: { currentUser: userData },
                        }}
                        variant="contained"
                        className="bg-primary  btnShadow  my-4 font-bold text-sm text-white  py-3   rounded-lg hover:bg-secondary  transition-all duration-300"
                      >
                        Créer une nouvelle commande
                      </Button>
                      {/* <Button  onClick={()=>handleUpdate(userData)}  variant="contained" className='bg-primary  btnShadow  my-4 font-bold text-sm text-white  py-3   rounded-lg hover:bg-secondary  transition-all duration-300'>Créer une nouvelle commande</Button> */}
                    </div>
                  </div>
                </Grid>
                <Grid xs={8}>
                  <Typography
                    variant="h6"
                    component="h6"
                    fontWeight={400}
                    sx={{ textAlign: "left" }}
                    color={"#27295B"}
                  >
                    Informations de base
                  </Typography>
                  <Stack direction="row" spacing={2} className="pt-4">
                    <TextField
                      label="Siren"
                      variant="outlined"
                      fullWidth
                      name="siren"
                      onChange={handleChange}
                      value={userData.siren}
                    />
                    <TextField
                      label="Nom de la Société"
                      variant="outlined"
                      fullWidth
                      value={userData.raisosociale}
                      name="raisosociale"
                      onChange={handleChange}
                    />
                    <FormControl fullWidth sx={{ textAlign: "left" }}>
                      <InputLabel id="demo-simple-select-label">
                        Civilité
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        // value={civilite}
                        label="Civilite"
                        name="civil"
                        onChange={handleChange}
                        value={
                          userData.civil !== "null" && userData.civil !== null
                            ? userData.civil
                            : "M"
                        }
                      >
                        <MenuItem value={"M"}>M</MenuItem>
                        <MenuItem value={"MME"}>MME</MenuItem>
                      </Select>
                    </FormControl>
                  </Stack>
                  <Stack direction="row" spacing={2} className="pt-4">
                    <TextField
                      label="Nom"
                      variant="outlined"
                      fullWidth
                      name="firstname"
                      value={userData.firstname}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      label="Prénom"
                      variant="outlined"
                      fullWidth
                      name="lastname"
                      value={userData.lastname}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      label="Téléphone"
                      variant="outlined"
                      fullWidth
                      name="telephone"
                      value={userData.telephone}
                      onChange={(e) => handleChange(e)}
                    />
                  </Stack>
                  <Stack direction="row" spacing={2} className="pt-4">
                    <TextField
                      label="Addresse email"
                      variant="outlined"
                      disabled
                      fullWidth
                      name="email"
                      value={userData.email}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      label="Ville"
                      variant="outlined"
                      fullWidth
                      name="ville"
                      value={userData.ville}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      label="Code postal"
                      variant="outlined"
                      fullWidth
                      name="postalcode"
                      value={userData.postalcode}
                      onChange={(e) => handleChange(e)}
                    />
                  </Stack>
                  <Stack direction="row" spacing={2} className="pt-4">
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="accounttype"
                          checked={
                            userData.accounttype.includes("professional")
                              ? true
                              : false
                          }
                          value={"professional"}
                          onChange={(e) => handleChange(e)}
                        />
                      }
                      label="Compte Professionnel"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="accounttype"
                          checked={
                            userData.accounttype.includes("free") ? true : false
                          }
                          value={"free"}
                          onChange={(e) => handleChange(e)}
                        />
                      }
                      label="Modèle libre"
                    />
                  </Stack>
                  {userData.accounttype.includes("professional") ? (
                    <>
                      <Typography
                        variant="h5"
                        component="h5"
                        fontWeight={"bold"}
                        pt={2}
                        sx={{ textAlign: "left" }}
                        color={"#27295B"}
                      >
                        Gestion des remises
                      </Typography>
                      <Typography
                        variant="h6"
                        component="h6"
                        fontWeight={400}
                        pt={0}
                        sx={{ textAlign: "left" }}
                        color={"#27295B"}
                      >
                        Remises annonces
                      </Typography>
                      <Stack direction="row" spacing={2} className="pt-4">
                        <TextField
                          label="Forfaitaires"
                          variant="outlined"
                          fullWidth
                          name="forfaitaires"
                          value={userData.forfaitaires}
                          onChange={(e) => handleChange(e)}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">% </InputAdornment>
                            ),
                          }}
                        />
                        <TextField
                          label="Modification"
                          variant="outlined"
                          fullWidth
                          name="modification"
                          value={userData.modification}
                          onChange={(e) => handleChange(e)}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">% </InputAdornment>
                            ),
                          }}
                        />
                      </Stack>{" "}
                    </>
                  ) : (
                    ""
                  )}
                  <Typography
                    variant="h6"
                    component="h6"
                    fontWeight={400}
                    pt={2}
                    sx={{ textAlign: "left" }}
                    color={"#27295B"}
                  >
                    Mode depaiement
                  </Typography>
                  <Stack direction="row" spacing={2}>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      name="radio-buttons-group"
                      row
                    >
                      <FormControlLabel
                        control={
                          <Radio
                            name="payment_plan"
                            checked={
                              userData.payment_plan === "imediate"
                                ? true
                                : false
                            }
                            value="imediate"
                            onChange={(e) => handleChange(e)}
                          />
                        }
                        label="Paiement Immédiat"
                      />
                      <FormControlLabel
                        control={
                          <Radio
                            name="payment_plan"
                            checked={
                              userData.payment_plan === "monthly" ? true : false
                            }
                            value="monthly"
                            onChange={(e) => handleChange(e)}
                          />
                        }
                        label=" Paiement mensuel"
                      />
                    </RadioGroup>
                  </Stack>
                  <Box sx={{ textAlign: "left" }}>
                    <Button
                      onClick={() => handleUpdate(userData)}
                      variant="contained"
                      className="bg-secondary btnShadow w-1/2  my-4 font-bold text-sm text-white  py-3   rounded-lg hover:bg-primary transition-all duration-300"
                    >
                      Mettre à jour
                    </Button>
                  </Box>
                </Grid>
              </Grid>

        <Box mt={4}>
          <Typography
            variant="h6"
            component="h6"
            fontWeight={400}
            sx={{ textAlign: "left", pb: "15px" }}
            color={"#27295B"}
          >
            Gestion des commandes
          </Typography>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>CDE N°</TableCell>
                  <TableCell>DENOMINATION</TableCell>
                  <TableCell>TYPE ANNONCES</TableCell>
                  <TableCell>DATE DE PUBLICATION</TableCell>
                  <TableCell>ACTIONS</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {visibleRows &&
                  visibleRows.map((val, key) => {
                    return (
                      <>
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                          key={"announce_data" + key}
                        >
                          <TableCell>{val.payment_order_id}</TableCell>
                          <TableCell>
                            {val.votre_annonce_json.denomination
                              ? val.votre_annonce_json.denomination
                              : ""}
                          </TableCell>
                          <TableCell>{val.form_type_text}</TableCell>
                          <TableCell>
                            {moment(val.date_parution).format("LL")}
                          </TableCell>
                          {/* <TableCell></TableCell> */}
                          <TableCell align="center">
                            <div>
                              <IconButton
                                aria-label="more"
                                aria-controls={`long-menu-${val.id}`}
                                aria-haspopup="true"
                                onClick={(e) => handleClick1(e, val.id)}
                              >
                                <MoreVertIcon />
                              </IconButton>
                              <Menu
                                id={`long-menu-${val.id}`}
                                anchorEl={anchorEls[val.id]}
                                keepMounted
                                open={Boolean(anchorEls[val.id])}
                                onClose={() => handleClose1(val.id)}
                              >
                                <MenuItem
                                  key={"Modifier"}
                                  onClick={(e) => handleClickEdit(e, val)}
                                >
                                  <MdEditSquare size={25} color="green" />{" "}
                                  &nbsp;&nbsp;{"Modifier"}
                                </MenuItem>
                                <MenuItem key={"voir les annonces"}>
                                  <Anchor
                                    href={`${process.env.REACT_APP_AWS_URL_STATIC_BUSYPLACE}assets/uploads/pdfgenerate/attestation/${val.attestation}`}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                  >
                                    <VisibilityIcon color={"primary"} />
                                    &nbsp;&nbsp;{"voir les annonces"}
                                  </Anchor>
                                </MenuItem>
                                <MenuItem
                                  onClick={(e) => handleClickEditDate(e, val)}
                                >
                                  {" "}
                                  <CalendarTodayIcon size={25} />{" "}
                                  &nbsp;&nbsp;date de parution
                                </MenuItem>

                                <MenuItem onClick={(e) => handleClick(e, val)}>
                                  {" "}
                                  <DeleteIcon
                                    size={25}
                                    style={{ color: "red" }}
                                  />{" "}
                                  &nbsp;&nbsp;Supprimer
                                </MenuItem>
                              </Menu>
                            </div>
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={AnnounceData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>

        <Box mt={4}>
          <Typography
            variant="h6"
            component="h6"
            fontWeight={400}
            sx={{ textAlign: "left", pb: "15px" }}
            color={"#27295B"}
          >
            Gestion des factures
          </Typography>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>N° DE FACTURE</TableCell>
                  <TableCell>DENOMINATION</TableCell>
                  <TableCell>TYPE ANNONCES</TableCell>
                  <TableCell>MONTANT</TableCell>
                  <TableCell>DATE DE PUBLICATION</TableCell>
                  <TableCell>ACTIONS</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {visibleRows &&
                  visibleRows.map((val, key) => {
                    return (
                      <>
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                          key={"announce" + key}
                        >
                          <TableCell>{val.payment_order_id}</TableCell>
                          <TableCell>
                            {val.votre_annonce_json.denomination
                              ? val.votre_annonce_json.denomination
                              : ""}
                          </TableCell>
                          <TableCell>{val.form_type_text}</TableCell>
                          <TableCell>{val.total}</TableCell>
                          <TableCell>
                            {moment(val.date_parution).format("LL")}
                          </TableCell>
                          {/* <TableCell></TableCell> */}
                          <TableCell align="center">
                            <div>
                              <IconButton
                                aria-label="more"
                                aria-controls={`long-menu1-${val.id}`}
                                aria-haspopup="true"
                                onClick={(e) => handleClick1Pending(e, val.id)}
                              >
                                <MoreVertIcon />
                              </IconButton>
                              <Menu
                                id={`long-menu1-${val.id}`}
                                anchorEl={anchorElsPending[val.id]}
                                keepMounted
                                open={Boolean(anchorElsPending[val.id])}
                                onClose={() => handleClose1Pending(val.id)}
                              >
                                <MenuItem
                                  onClick={(e) =>
                                    handleClickEditInvoice(e, val)
                                  }
                                >
                                  <CreateIcon size={25} />
                                  &nbsp;&nbsp; Modifier
                                </MenuItem>
                                {/* <MenuItem onClick={() => console.log('Voir les annonces')}>  <Link className="" href={`${process.env.REACT_APP_API_URL}uploads/pdfgenerate/invoice/${val.votre_pdf}`} target="_blank" rel="noopener">
                                        <VisibilityIcon color={'primary'} />
                                      </Link> Voir les annonces</MenuItem> */}
                                <MenuItem key={"voir la facture"}>
                                  <Anchor
                                    href={`${process.env.REACT_APP_AWS_URL_STATIC_BUSYPLACE}assets/uploads/pdfgenerate/invoice/${val.votre_pdf}`}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                  >
                                    <VisibilityIcon color={"primary"} /> &nbsp;{" "}
                                    {"voir la facture"}
                                  </Anchor>
                                </MenuItem>
                                {/* <MenuItem onClick={(e) => handleClickEditDate(e, val)}> <CalendarTodayIcon size={25}  /> &nbsp;&nbsp;date de parution</MenuItem> */}

                                <MenuItem onClick={(e) => handleClick(e, val)}>
                                  {" "}
                                  <DeleteIcon
                                    size={25}
                                    style={{ color: "red" }}
                                  />{" "}
                                  &nbsp;&nbsp;Supprimer
                                </MenuItem>
                              </Menu>
                            </div>
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={AnnounceData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>

        <Box mt={4}>
          <Typography
            variant="h6"
            component="h6"
            fontWeight={400}
            sx={{ textAlign: "left", pb: "15px" }}
            color={"#27295B"}
          >
            Annonces en attente (devis)
          </Typography>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>N° DE FACTURE</TableCell>
                  <TableCell>DENOMINATION</TableCell>
                  <TableCell>TYPE ANNONCES</TableCell>
                  <TableCell>MONTANT</TableCell>
                  <TableCell>DATE DE PUBLICATION</TableCell>
                  <TableCell>ACTIONS</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {visibleRowsPending &&
                  visibleRowsPending.map((val, key) => {
                    return (
                      <>
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                          key={"announce" + key}
                        >
                          <TableCell>{val.payment_order_id}</TableCell>
                          <TableCell>
                            {val.votre_annonce_json.denomination
                              ? val.votre_annonce_json.denomination
                              : ""}
                          </TableCell>
                          <TableCell>{val.form_type_text}</TableCell>
                          <TableCell>{val.total}</TableCell>
                          <TableCell>
                            {moment(val.date_parution).format("LL")}
                          </TableCell>
                          {/* <TableCell></TableCell> */}
                          <TableCell align="center">
                            <div>
                              <IconButton
                                aria-label="more"
                                aria-controls={`long-menu1-${val.id}`}
                                aria-haspopup="true"
                                onClick={(e) => handleClick1Pending(e, val.id)}
                              >
                                <MoreVertIcon />
                              </IconButton>
                              <Menu
                                id={`long-menu1-${val.id}`}
                                anchorEl={anchorElsPending[val.id]}
                                keepMounted
                                open={Boolean(anchorElsPending[val.id])}
                                onClose={() => handleClose1Pending(val.id)}
                              >
                                <MenuItem
                                  key={"Envoyer"}
                                  onClick={(e) => handleClickSend(e, val)}
                                >
                                  <FiSend size={25} color="green" />{" "}
                                  &nbsp;&nbsp;{"Envoyer"}
                                </MenuItem>
                                {/* <MenuItem onClick={(e) => handleClickEditInvoice(e, val)} ><CreateIcon size={25}  />&nbsp;&nbsp; Modifier</MenuItem> */}
                                <MenuItem
                                  key={"Modifier"}
                                  onClick={(e) => handleClickEdit(e, val)}
                                >
                                  <MdEditSquare size={25} color="green" />{" "}
                                  &nbsp;&nbsp;{"Modifier"}
                                </MenuItem>
                                <MenuItem key={"voir la facture"}>
                                  <Anchor
                                    href={`${process.env.REACT_APP_AWS_URL_STATIC_BUSYPLACE}assets/uploads/pdfgenerate/invoice/${val.votre_pdf}`}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                  >
                                    <VisibilityIcon color={"primary"} /> &nbsp;{" "}
                                    {"voir la facture"}
                                  </Anchor>
                                </MenuItem>
                                {/* <MenuItem onClick={(e) => handleClickEditDate(e, val)}> <CalendarTodayIcon size={25}  /> &nbsp;&nbsp;date de parution</MenuItem> */}

                                <MenuItem onClick={(e) => handleClick(e, val)}>
                                  {" "}
                                  <DeleteIcon
                                    size={25}
                                    style={{ color: "red" }}
                                  />{" "}
                                  &nbsp;&nbsp;Supprimer
                                </MenuItem>
                              </Menu>
                            </div>
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={AnnouncePendingData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </div>

      <Dialog
        sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
        maxWidth="xs"
        // TransitionProps={{ onEntering: handleEntering }}
        open={ConfirmOpen}
      >
        <DialogTitle>Are you sure ?</DialogTitle>
        <DialogContent dividers>
          <p>Are you sure want to delete ?</p>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCancel}>
            No
          </Button>
          <Button onClick={handleOk}>Yes</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openModelDate}
        onClose={handleCloseModelDate}
        maxWidth={"lg"}
      >
        <DialogTitle>Date de parution</DialogTitle>
        <DialogContent>
          <DialogContentText>Date de parution</DialogContentText>

          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale="fr"
            localeText={
              frFR.components.MuiLocalizationProvider.defaultProps.localeText
            }
          >
            <DatePicker
              value={dayjs(publicationDate)}
              name="date_typepublication"
              format={"DD/MM/YYYY"}
              onChange={(date) => setPublicationDate(date.$d)}
              customInput={
                <TextField
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  size="small"
                />
              }
            />
          </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModelDate}>Annuler</Button>
          {/* <Button onClick={handleSubmitModel}>Enregistrer</Button> */}
          <LoadingButton
            size="small"
            onClick={handleSubmitModelDate}
            startIcon={<SaveIcon />}
            loading={loading}
            loadingPosition="start"
            variant="contained"
          >
            <span>Sauvegarder</span>
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Dialog open={openModel} onClose={handleCloseModel} maxWidth={"lg"}>
        <DialogTitle>Adresse de facturation </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {/* To subscribe to this website, please enter your email address here. We
            will send updates occasionally. */}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="billing_address_denomination"
            name="billing_address_denomination"
            value={editFormData.billing_address_denomination}
            error={
              !editFormData.billing_address_denomination && submitted
                ? true
                : false
            }
            onChange={handleChangeModel}
            label="Dénomination"
            type="text"
            fullWidth
            variant="standard"
          />
          <TextField
            autoFocus
            margin="dense"
            id="billing_address_civility"
            name="billing_address_civility"
            value={editFormData.billing_address_civility}
            error={
              !editFormData.billing_address_civility && submitted ? true : false
            }
            onChange={handleChangeModel}
            label="Civilité"
            type="text"
            fullWidth
            variant="standard"
          />
          <TextField
            autoFocus
            margin="dense"
            id="billing_address_nom"
            name="billing_address_nom"
            value={editFormData.billing_address_nom}
            error={
              !editFormData.billing_address_nom && submitted ? true : false
            }
            onChange={handleChangeModel}
            label="Nom"
            type="text"
            fullWidth
            variant="standard"
          />
          <TextField
            autoFocus
            margin="dense"
            id="billing_address_prenom"
            name="billing_address_prenom"
            value={editFormData.billing_address_prenom}
            error={
              !editFormData.billing_address_prenom && submitted ? true : false
            }
            onChange={handleChangeModel}
            label="Prénom"
            type="text"
            fullWidth
            variant="standard"
          />
          <TextField
            autoFocus
            margin="dense"
            id="billing_address_address"
            name="billing_address_address"
            value={editFormData.billing_address_address}
            error={
              !editFormData.billing_address_address && submitted ? true : false
            }
            onChange={handleChangeModel}
            label="Adresse"
            type="text"
            fullWidth
            variant="standard"
          />
          <TextField
            autoFocus
            margin="dense"
            id="billing_address_code_postal"
            name="billing_address_code_postal"
            value={editFormData.billing_address_code_postal}
            error={
              !editFormData.billing_address_code_postal && submitted
                ? true
                : false
            }
            onChange={handleChangeModel}
            label="Code postal"
            type="text"
            fullWidth
            variant="standard"
          />
          <FormControl fullWidth margin="dense">
            <InputLabel id="demo-simple-select-label">Ville</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="billing_address_ville_id"
              name="billing_address_ville_id"
              value={editFormData.billing_address_ville_id}
              error={
                !editFormData.billing_address_ville_id && submitted
                  ? true
                  : false
              }
              label="Ville"
              onChange={handleChangeModel}
            >
              {editFormData.billing_address_villeArr.map((val, key) => {
                return <MenuItem value={val.id}>{val.city}</MenuItem>;
              })}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModel}>Annuler</Button>
          {/* <Button onClick={handleSubmitModel}>Enregistrer</Button> */}
          <LoadingButton
            size="small"
            onClick={handleSubmitModel}
            startIcon={<SaveIcon />}
            loading={loading}
            loadingPosition="start"
            variant="contained"
          >
            <span>Enregistrer</span>
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
